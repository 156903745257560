import { faDownload, faExternalLink, faFile, faNoteSticky, faPenNib, faVideo } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import bgHeaderBottom from "./assets/images/backgrounds/bg_header_bottom.svg"
import bgLightBottom from "./assets/images/backgrounds/bg_light_bottom.svg"
import bgLightTop from "./assets/images/backgrounds/bg_light_top.svg"
import bars from "./assets/images/bars.svg"
import logo from "./assets/images/logo.png"
import marcel from "./assets/images/people/marcel.png"
import marvin from "./assets/images/people/marvin.jpg"
import michael from "./assets/images/people/michael.jpg"
import phillip from "./assets/images/people/phillip.jpg"
import timo from "./assets/images/people/timo.jpg"
import prototype from "./assets/images/prototype.png"
import screenleft from "./assets/images/screens/screen_left.png"
import screenright from "./assets/images/screens/screen_right.png"

const App = () => {
    const [isNavMobileOpen, setIsNavMobileOpen] = useState<boolean>(false)
    const [activeTab, setActiveTab] = useState<"dokumente" | "prototype" | "video" | "paper">("dokumente")

    return <div id="top">
        {/* Header */}
        <div className="bg-green-800 text-white">
            {/* Navigation */}
            <nav className="fixed w-full py-4 bg-green-800 z-10">
                <div className="container md:flex justify-between">
                    <div className="flex items-center justify-between">
                        {/* Logo */}
                        <a href="#top" className="flex items-center">
                            {/** MC: The not defined height creates flickering when loading... */}
                            <img src={logo} alt="Logo" className="min-w-[25px] w-[25px] mr-2" />
                            <h6 className="font-semibold text-lg">About tomorrow</h6>
                        </a>

                        {/* Mobile Menu Button */}
                        <button onClick={() => setIsNavMobileOpen(!isNavMobileOpen)} className="md:hidden">
                            <img src={bars} alt="Menu" className="min-w-[25px] w-[25px] mr-3" />
                        </button>
                    </div>

                    {/* Links */}
                    <ul className={`transition-all duration-500 ${isNavMobileOpen ? "block" : "hidden"} mt-4 md:mt-0 md:flex justify-center items-center md:gap-10`}>
                        <li className="mb-2 md:mb-0"><a href="#warum">Warum?</a></li>
                        <li className="mb-2 md:mb-0"><a href="#abgaben">Abgaben</a></li>
                        <li className="mb-2 md:mb-0"><a href="#team">Team</a></li>
                    </ul>
                </div>
            </nav>

            {/* Header Content */}
            <header className="container flex flex-col items-center justify-center md:min-h-[20rem] pb-20 xl:pb-5 pt-32">
                <h1 className="text-4xl text-center font-bold mb-4">Upgrade Dein Leben! Kauf Langlebig!</h1>
                <p className="text-lg text-center md:w-1/2 mb-6">About Tomorrow ist eine App, mit der du die Langlebigkeit von Produkten checken kannst. Mit deinen eigenen Erfahrungen hilfst du anderen, nachhaltige Kaufentscheidungen zu treffen.</p>
                <div className="md:flex text-center w-full md:w-auto">
                    <a href="#abgaben" className="block bg-green-500 border-2 border-green-500 text-green-800 hover:bg-green-600 hover:border-green-600 font-semibold text-center text-lg rounded-lg py-2 md:w-48 mb-4 md:mb-0 md:mr-4">Abgaben</a>
                    <a href="https://www.youtube.com/watch?v=AB-wAAqAFAE" target="_blank" className="block bg-green-800 border-2 border-green-500 text-green-500 hover:bg-green-500 hover:text-green-800 font-semibold text-center text-lg rounded-lg py-2 md:w-48" rel="noreferrer">Video anschauen</a>
                </div>
            </header>
        </div>

        {/* Background */}
        <img src={bgHeaderBottom} alt="" className="w-full h-auto mt-[-1px]" />


        <div className="flex flex-col md:flex-row items-center justify-center mt-[-4rem] md:mt-[-5rem] gap-8 mb-10 md:mb-20">
            <img src={screenleft} alt="Screen" className=" h-auto w-80 md:w-96" />
            <img src={screenright} alt="Screen" className=" h-auto w-80 md:w-96" />
        </div>
        {/* Header end */}

        <main>
            {/* Warum? */}
            <div id="warum">
                <div className="container flex flex-col items-center mb-10 md:mb-20">
                    <h2 className="text-4xl text-center font-bold mb-4">Warum das ganze?</h2>
                    <p className="text-gray-600 text-center md:w-1/2">Nachhaltige E-Autos? Coole Sache, aber wenn sie nur 100.000km fahren, reicht das nicht, um unseren Planeten zu retten. About Tomorrow möchte auf die Langlebigkeit von Produkten eingehen und Nutzern helfen, bessere Kaufentscheidungen zu treffen und damit Unternehmen motivieren, langlebige Produkte zu entwickeln.</p>
                </div>

                {/* Background Top */}
                <img src={bgLightTop} alt="" className="w-full mb-[-1px]" />
            </div>
            {/* Abgaben */}
            <div id="abgaben">
                <div className="bg-green-100">
                    <div className="container">
                        <div className=" flex flex-col items-center pb-10 md:pb-20">
                            <h2 className="text-4xl text-center font-bold mb-4">Abgaben</h2>
                            <p className="text-gray-600 text-center md:w-1/2">Entdecke “About Tomorrow”.</p>
                        </div>

                        <div className="lg:flex gap-6">
                            <ul className="lg:w-1/3 mb-8 lg:mb-0">
                                <li className={`transition-all duration-300 ${activeTab === "dokumente" ? "bg-white rounded-lg" : ""}`}>
                                    <button className={`transition-all duration-200 flex items-center font-semibold ${activeTab === "dokumente" ? "" : "opacity-50 hover:opacity-100"} w-full px-4 py-3`} onClick={() => setActiveTab("dokumente")}>
                                        <FontAwesomeIcon icon={faFile} className="text-green-500 h-5 w-6 mr-4" />
                                        <span>Dokumente</span>
                                    </button>
                                </li>
                                <li className={`transition-all duration-300 ${activeTab === "prototype" ? "bg-white rounded-lg" : ""}`}>
                                    <button className={`transition-all duration-200 flex items-center font-semibold ${activeTab === "prototype" ? "" : "opacity-50 hover:opacity-100"} w-full px-4 py-3`} onClick={() => setActiveTab("prototype")}>
                                        <FontAwesomeIcon icon={faPenNib} className="text-green-500 h-5 w-6 mr-4" />
                                        <span>Prototype</span>
                                    </button>
                                </li>
                                <li className={`transition-all duration-300 ${activeTab === "video" ? "bg-white rounded-lg" : ""}`}>
                                    <button className={`transition-all duration-200 flex items-center font-semibold ${activeTab === "video" ? "" : "opacity-50 hover:opacity-100"} w-full px-4 py-3`} onClick={() => setActiveTab("video")}>
                                        <FontAwesomeIcon icon={faVideo} className="text-green-500 h-5 w-6 mr-4" />
                                        <span>Video</span>
                                    </button>
                                </li>
                                <li className={`transition-all duration-300 ${activeTab === "paper" ? "bg-white rounded-lg" : ""}`}>
                                    <button className={`transition-all duration-200 flex items-center font-semibold ${activeTab === "paper" ? "" : "opacity-50 hover:opacity-100"} w-full px-4 py-3`} onClick={() => setActiveTab("paper")}>
                                        <FontAwesomeIcon icon={faNoteSticky} className="text-green-500 h-5 w-6 mr-4" />
                                        <span>Paper</span>
                                    </button>
                                </li>
                            </ul>

                            <div className="lg:w-2/3">
                                {activeTab === "dokumente" && <div className="transition-all rounded-lg bg-white p-8">
                                    <h3 className="text-xl font-bold mb-4">Dokumente</h3>

                                    <h4 className="mb-2 font-semibold">Hubert Maurer</h4>
                                    <a href="/downloads/user_persona_hubert_maurer.pdf" download="user_persona_hubert_maurer.pdf" className="block bg-green-500 border-2 border-green-500 text-green-800 hover:bg-green-600 hover:border-green-600 font-semibold text-center rounded-lg py-2 md:w-96 mb-4">
                                        <FontAwesomeIcon icon={faDownload} className="text-green-800 h-auto w-4 mr-4" />
                                        <span>Persona & Human Needs (PDF)</span>
                                    </a>
                                    <a href="/downloads/customer_journey_hubert_maurer.pdf" download="customer_journey_hubert_maurer.pdf" className="block bg-green-500 border-2 border-green-500 text-green-800 hover:bg-green-600 hover:border-green-600 font-semibold text-center rounded-lg py-2 md:w-96 mb-4">
                                        <FontAwesomeIcon icon={faDownload} className="text-green-800 h-auto w-4 mr-4" />
                                        <span>User Journey (PDF)</span>
                                    </a>

                                    <h4 className="mb-2 font-semibold">Laura Schmidt</h4>
                                    <a href="/downloads/user_persona_laura_schmidt.pdf" download="user_persona_laura_schmidt.pdf" className="block bg-green-500 border-2 border-green-500 text-green-800 hover:bg-green-600 hover:border-green-600 font-semibold text-center rounded-lg py-2 md:w-96 mb-4">
                                        <FontAwesomeIcon icon={faDownload} className="text-green-800 h-auto w-4 mr-4" />
                                        <span>Persona & Human Needs (PDF)</span>
                                    </a>
                                    <a href="/downloads/customer_journey_laura_schmidt.pdf" download="customer_journey_laura_schmidt.pdf" className="block bg-green-500 border-2 border-green-500 text-green-800 hover:bg-green-600 hover:border-green-600 font-semibold text-center rounded-lg py-2 md:w-96 mb-4">
                                        <FontAwesomeIcon icon={faDownload} className="text-green-800 h-auto w-4 mr-4" />
                                        <span>User Journey (PDF)</span>
                                    </a>
                                    <h4 className="mb-2 font-semibold">Testing</h4>
                                    <a href="/downloads/evaluation_strategy.pdf" download="evaluation_strategy.pdf" className="block bg-green-500 border-2 border-green-500 text-green-800 hover:bg-green-600 hover:border-green-600 font-semibold text-center rounded-lg py-2 md:w-96 mb-4">
                                        <FontAwesomeIcon icon={faDownload} className="text-green-800 h-auto w-4 mr-4" />
                                        <span>Evaluation Strategy & Test Plan (PDF)</span>
                                    </a>
                                    <a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLScpUuGxHMIxmaFu1lclkEC1oFOgSoHaReHlmalG1hveqvbtEg/viewform" className="block bg-green-500 border-2 border-green-500 text-green-800 hover:bg-green-600 hover:border-green-600 font-semibold text-center rounded-lg py-2 md:w-96 mb-4" rel="noreferrer">
                                        <FontAwesomeIcon icon={faExternalLink} className="text-green-800 h-auto w-4 mr-4" />
                                        <span>User Test Fragebogen</span>
                                    </a>
                                    <a target="_blank" href="https://docs.google.com/spreadsheets/d/1V5GXBAyXJVZLHPkDCT1yAGpSnotSUqprZmaYm1EbrBc/edit?usp=sharing" className="block bg-green-500 border-2 border-green-500 text-green-800 hover:bg-green-600 hover:border-green-600 font-semibold text-center rounded-lg py-2 md:w-96 mb-4" rel="noreferrer">
                                        <FontAwesomeIcon icon={faExternalLink} className="text-green-800 h-auto w-4 mr-4" />
                                        <span>Testauswertung</span>
                                    </a>
                                    <a href="/downloads/testauswertung.xlsx" download="testauswertung.xlsx" className="block bg-green-500 border-2 border-green-500 text-green-800 hover:bg-green-600 hover:border-green-600 font-semibold text-center rounded-lg py-2 md:w-96 mb-4">
                                        <FontAwesomeIcon icon={faDownload} className="text-green-800 h-auto w-4 mr-4" />
                                        <span>Testauswertung (XLSX)</span>
                                    </a>
                                    <h4 className="mb-2 font-semibold">Weitere Dokumente</h4>
                                    <a href="/downloads/ux_information_architecture.pdf" download="ux_information_architecture.pdf" className="block bg-green-500 border-2 border-green-500 text-green-800 hover:bg-green-600 hover:border-green-600 font-semibold text-center rounded-lg py-2 md:w-96 mb-4">
                                        <FontAwesomeIcon icon={faDownload} className="text-green-800 h-auto w-4 mr-4" />
                                        <span>Information Architecture (PDF)</span>
                                    </a>
                                    <a href="/downloads/letter_to_the_CEO.pdf" download="letter_to_the_CEO.pdf" className="block bg-green-500 border-2 border-green-500 text-green-800 hover:bg-green-600 hover:border-green-600 font-semibold text-center rounded-lg py-2 md:w-96 mb-4">
                                        <FontAwesomeIcon icon={faDownload} className="text-green-800 h-auto w-4 mr-4" />
                                        <span>Letter to the CEO (PDF)</span>
                                    </a>
                                    <p className="mb-4 text-gray-500">In den Inhalten wurden <a target="_blank" className="underline" href="https://helpx.adobe.com/de/fonts/using/font-licensing.html" rel="noreferrer">Adobe Fonts</a> und <a target="_blank" className="underline" href="https://fontawesome.com/license" rel="noreferrer">Font Awesome</a> verwendet. Beide sind für persönliche und kommerzielle Verwendung lizenziert. (Siehe Links)</p>
                                </div>}
                                {activeTab === "prototype" && <div className="transition-all rounded-lg bg-white p-8">
                                    <h3 className="text-xl font-bold mb-4">Prototype</h3>
                                    <p className="mb-4">Unser klickbarer High Fidelity Prototype deckt die Grundfunktionen unser App ab und bildet die Grundlage für unsere User Tests. Der Prototype wurde mit Hilfe in Adobe XD umgesetzt.</p>
                                    <img src={prototype} className="w-full h-auto mb-4" alt="Vorschau des Prototypes in Adobe Xd" />
                                    <a href="/downloads/prototype.pdf" download="prototype.pdf" className="block bg-green-500 border-2 border-green-500 text-green-800 hover:bg-green-600 hover:border-green-600 font-semibold text-center rounded-lg py-2 md:w-96 mb-4">
                                        <FontAwesomeIcon icon={faDownload} className="text-green-800 h-auto w-4 mr-4" />
                                        <span>Download PDF</span>
                                    </a>
                                    <a target="_blank" href="https://xd.adobe.com/view/84ff3ede-91ba-48d1-b1d0-a365486e7c93-d1ac/?fullscreen" className="block bg-green-500 border-2 border-green-500 text-green-800 hover:bg-green-600 hover:border-green-600 font-semibold text-center rounded-lg py-2 md:w-96 mb-4" rel="noreferrer">
                                        <FontAwesomeIcon icon={faExternalLink} className="text-green-800 h-auto w-4 mr-4" />
                                        <span>Live Prototype</span>
                                    </a>
                                    <p className="mb-4 text-gray-500">In den Inhalten wurden <a target="_blank" className="underline" href="https://helpx.adobe.com/de/fonts/using/font-licensing.html" rel="noreferrer">Adobe Fonts</a> und <a target="_blank" className="underline" href="https://fontawesome.com/license" rel="noreferrer">Font Awesome</a> verwendet. Beide sind für persönliche und kommerzielle Verwendung lizenziert. (Siehe Links)</p>
                                </div>}
                                {activeTab === "video" && <div className="transition-all rounded-lg bg-white p-8">
                                    <h3 className="text-xl font-bold mb-4">Video</h3>
                                    <p className="mb-4">Die Produktion unseres Videos hat uns eine Menge Spaß bereitet. Die Postproduktion wurde mit Adobe Premiere Pro umgesetzt. Die verwendeten Medien stehen alle unter Creative Commons Zero (CC0).</p>
                                    <iframe src="https://www.youtube.com/embed/AB-wAAqAFAE" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen style={{aspectRatio: 16 / 9,width: "100%"}} />
                                    <a href="/downloads/about_tomorrow_video_compressed.mp4" download="about_tomorrow_video_compressed.mp4" className="block bg-green-500 border-2 border-green-500 text-green-800 hover:bg-green-600 hover:border-green-600 font-semibold text-center rounded-lg py-2 md:w-48 mb-4 mt-4">
                                        <FontAwesomeIcon icon={faDownload} className="text-green-800 h-auto w-4 mr-4" />
                                        <span>Download</span>
                                    </a>
                                    <p className="mb-4 text-gray-500">In den Inhalten wurden <a target="_blank" className="underline" href="https://helpx.adobe.com/de/fonts/using/font-licensing.html" rel="noreferrer">Adobe Fonts</a> und <a target="_blank" className="underline" href="https://fontawesome.com/license" rel="noreferrer">Font Awesome</a> verwendet. Beide sind für persönliche und kommerzielle Verwendung lizenziert. (Siehe Links)</p>
                                </div>}
                                {activeTab === "paper" && <div className="transition-all rounded-lg bg-white p-8">
                                    <h3 className="text-xl font-bold mb-2">Paper</h3>
                                    <h4 className="mb-4">Thema: Können digitale Hilfsmittel die Kaufentscheidung für langlebigere Produkte stärker erhöhen, als herkömmliche Hilfsmittel?</h4>
                                    <a href="/downloads/paper/scientific_paper.pdf" download="about_tomorrow_scientific_paper.pdf" className="block bg-green-500 border-2 border-green-500 text-green-800 hover:bg-green-600 hover:border-green-600 font-semibold text-center rounded-lg py-2 md:w-48 mb-4">
                                        <FontAwesomeIcon icon={faDownload} className="text-green-800 h-auto w-4 mr-4" />
                                        <span>Download</span>
                                    </a>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Background Bottom */}
                <img src={bgLightBottom} alt="" className="w-full mt-[-1px] mb-10 md:mb-20" />
            </div>
            {/* Team */}
            <div id="team" className="container flex flex-col items-center mb-10 md:mb-20">
                <h2 className="text-4xl text-center font-bold md:mb-10">Team</h2>

                <div className="grid grid-cols-1 md:grid-cols-3 items-center gap-3 mb-3">
                    <div className="flex flex-col items-center bg-green-100 rounded-lg p-8 text-center">
                        <img src={michael} alt="placeholder" className="rounded-full h-24 w-24 mb-4" />
                        <h4 className="font-semibold">Michael</h4>
                        <p className="text-gray-600">Kreativ</p>
                    </div>
                    <div className="flex flex-col items-center bg-green-100 rounded-lg p-8 text-center">
                        <img src={marvin} alt="placeholder" className="rounded-full h-24 w-24 mb-4" />
                        <h4 className="font-semibold">Marvin</h4>
                        <p className="text-gray-600">Herausrangend</p>
                    </div>
                    <div className="flex flex-col items-center bg-green-100 rounded-lg p-8 text-center">
                        <img src={marcel} alt="placeholder" className="rounded-full h-24 w-24 mb-4" />
                        <h4 className="font-semibold">Marcel</h4>
                        <p className="text-gray-600">Faszinierend</p>
                    </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 justify-center gap-3">
                    <div className="flex flex-col items-center bg-green-100 rounded-lg p-8 text-center">
                        <img src={timo} alt="placeholder" className="rounded-full h-24 w-24 mb-4" />
                        <h4 className="font-semibold">Timo</h4>
                        <p className="text-gray-600">Charismatisch</p>
                    </div>
                    <div className="flex flex-col items-center bg-green-100 rounded-lg p-8 text-center">
                        <img src={phillip} alt="placeholder" className="rounded-full h-24 w-24 mb-4" />
                        <h4 className="font-semibold">Philipp</h4>
                        <p className="text-gray-600">Genial</p>
                    </div>
                </div>
            </div>
        </main>

        {/* Footer */}
        <footer className="bg-green-800 pt-10 pb-4">
            <div className="container flex flex-col items-center text-white">
                <h3 className="text-3xl font-bold mb-4">About tomorrow</h3>
                <ul className="flex flex-col md:flex-row gap-3 text-center md:gap-16 mb-16">
                    <li><a href="#warum">Warum?</a></li>
                    <li><a href="#abgaben">Abgaben</a></li>
                    <li><a href="#team">Team</a></li>
                </ul>

                <p className="text-center text-green-200">Copyright &copy; About Tomorrow {new Date().getFullYear()}</p>
            </div>
        </footer>
    </div >
}

export default App